import {Link} from "react-router-dom";
import React from "react";

const Footer = () => {
    return (
        <footer className="lg:-translate-y-[8.05rem] md:-translate-y-0 pt-[72px] bg-footerbg h-full">
            <div className="flex justify-evenly lg:items-start sm:items-center lg:pb-0 sm:pb-[72px]  lg:flex-row sm:flex-col xl:px-0 xl:pt-0 lg:px-10 lg:pt-10 gap-16 relative after:sm:hidden
            after:lg:block after:h-[8.05rem] after:bg-footerbg after:absolute after:-bottom-32 after:left-0 after:w-full">
                <div className="flex flex-col xxl:gap-32 xl:gap-28 lg:gap-20 sm:gap-16 h-full justify-between">
                    <div className="">
                        <h2 className="uppercase font-bold text-primary mb-[23px] lg:text-lg sm:text-2xl">Brand-o-rama</h2>
                        <p className="text-footertext text-xs xl:w-full lg:w-[30vw] md:w-[53vw] sm:w-[80vw] leading-[21px]">Brand-o-rama is a creative agency that helps brands grow through strategy, design and technology.</p>
                    </div>
                    <div className="">
                        <input type="text" className="bg-transparent w-[100%] focus:outline-0 focus:border-b-2 focus:border-gray-400 border-b border-gray-400 active:border-b-2 active:border-gray-400 text-xs pt-[17px] pb-[19px] text-footertext" placeholder="ENTER YOUR EMAIL TO SUBSCRIBE"/>
                    </div>
                </div>
                <div className="flex flex-col md:px-0 smm:px-10 sm:px-6 gap-16 justify-between">
                    <div className="flex md:gap-20 sm:gap-10 ">
                        <div>
                            <h2 className="uppercase font-bold text-lg text-footertext mb-[26px]">Work</h2>
                            <ul className="flex flex-col gap-4">
                                <li className="text-footertext text-xs">Case Studies</li>
                                <li className="text-footertext text-xs"> <Link to="/ourwork" >Our Work</Link></li>
                                <li className="text-footertext text-xs">Our Process</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="uppercase font-bold text-lg text-footertext mb-[26px]">Team</h2>
                            <ul className="flex flex-col gap-4">
                                <li className="text-footertext text-xs"><Link to="/aboutus" >About Us</Link></li>
                                <li className="text-footertext text-xs">Careers</li>
                                <li className="text-footertext text-xs">Contact Us </li>
                                <li className="text-footertext text-xs">For Creators</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="uppercase font-bold text-lg text-footertext mb-[26px]">Follow</h2>
                            <ul className="flex flex-col gap-4">
                                <li className="text-footertext text-xs">Instagram</li>
                                <li className="text-footertext text-xs">Twitter</li>
                                <li className="text-footertext text-xs">Facebook</li>
                                <li className="text-footertext text-xs">Linkedin</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <p className="text-xs text-footertext">Brandorama• © All Rights Reserved • Terms of Service • Privacy Policy</p>
                    </div>
                </div>
            </div>


        </footer>
    );

}

export default Footer;
