import why1 from '../images/why1.png'
import why2 from '../images/why2.png'
import why3 from '../images/why3.png'
import why4 from '../images/why4.png'
import handshake from '../images/handshake.svg'
import growth from '../images/growth.svg'
import rocket from '../images/rocket.svg'
import awarness from '../images/awarness.svg'
import mediametrics from '../images/mediametrics.svg'
import personalization from '../images/personalization.svg'
import comversionrates from '../images/comversionrates.svg'
import budget from '../images/budget.svg'
import supportlead from '../images/supportlead.svg'
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import {useState} from "react";


const Why = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {

        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);

    }
    return (
        <>
            <div className="flex flex-col justify-center items-center bg-white lg:-translate-y-[8.05rem] sm:-translate-y-0 xl:gap-24 sm:gap-16 bg-whybg bg-no-repeat overflow-hidden ">
                <h2 className="lg:text-[42px] sm:text-[25px] font-[900] text-secondary mt-28 ">Why Brand-0-Rama?</h2>
                <div className="scroller-4 lg:w-[80vw] overflow-hidden sm:w-full">
                    <div className="flex gap-14 scroller__inner-4 ">
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40  justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard ">
                            <img src={handshake} alt="handshake" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Raises brand advocacy and
                                credibility</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg  flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={growth} alt="growth" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Increases authenticity and
                                Growth</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={rocket} alt="rocket" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Boost Social Media Reach</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={awarness} alt="awarness" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Build brand awareness</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={mediametrics} alt="mediametrics" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Boost social media metrics</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={personalization} alt="personalization" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center px-2.5 pt-3">Increase Personalization</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={supportlead} alt="supportlead" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Support lead generation</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg  flex flex-none flex-col h-40 w-40 justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={budget} alt="budget" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  px-2.5 pt-3">Easily accessible and budget
                                friendly</p>
                        </div>
                        <div className="border-[6px] bg-whycardbg flex flex-none flex-col h-40 w-40  justify-center items-center border-whyborder rounded-t-[91px] bg-whybgcard">
                            <img src={comversionrates} alt="comversionrates" className="h-[50px] w-[50px]" />
                            <p className="text-xs text-center  lg:px-2.5 pt-3">Improves conversion rates</p>
                        </div>
                    </div>
                </div>
                <h2 className="lg:text-[42px] sm:text-[25px] font-[900] text-secondary ">How it works</h2>
                <div className="flex xl:gap-20 lg:gap-10 md:gap-16 sm:gap-10 md:flex-row sm:flex-col justify-center items-center">
                    <div className="flex lg:flex-row sm:flex-col xl:gap-20 lg:gap-10 md:gap-16 sm:gap-10">
                        <div className="flex flex-col gap-4 w-[214px]">
                            <div className="h-[132px] self-center w-[132px]">
                                <img src={why1} alt="how1" className="" />
                            </div>
                            <h3 className="text-lg font-[900] text-secondary"><span className="h-[31px] font-normal w-[33px] px-[12px] py-[4px] rounded-[5px] text-sm bg-hownumber text-white text-center mr-7">1</span>Place Order</h3>
                            <p className="text-sm leading-[25px] text-[#1F2021]">Pick the content type that
                                your brand needs</p>
                        </div>
                        <div className="flex flex-col gap-4 w-[214px]">
                            <div className="h-[132px] self-center w-[132px]">
                                <img src={why2} alt="how1" className="" />
                            </div>
                            <h3 className="text-lg font-[900] text-secondary"><span className="h-[31px] font-normal w-[33px] px-[12px] py-[4px] rounded-[5px] text-sm bg-hownumber text-white text-center mr-7">2</span>Complete Brief</h3>
                            <p className="text-sm leading-[25px] text-[#1F2021]">Fill out our creative brief to
                                learn more about your
                                project</p>
                        </div>
                    </div>
                    <div className="flex lg:flex-row sm:flex-col xl:gap-20 lg:gap-10 md:gap-16 sm:gap-10">
                        <div className="flex flex-col gap-4 w-[214px]">
                            <div className="h-[132px] self-center w-[132px]">
                                <img src={why3} alt="how1" className="" />
                            </div>
                            <h3 className="text-lg font-[900] text-secondary"><span className="h-[31px] font-normal w-[33px] px-[12px] py-[4px] rounded-[5px] text-sm bg-hownumber text-white text-center mr-7">3</span>Ship product</h3>
                            <p className="text-sm leading-[25px] text-[#1F2021]">A dedicated account
                                manager will get in touch to
                                confirm shipping details</p>
                        </div>
                        <div className="flex flex-col gap-4 w-[214px]">
                            <div className="h-[132px] self-center w-[132px]">
                                <img src={why4} alt="how1" className="" />
                            </div>

                            <h3 className="text-lg font-[900] text-secondary"><span className="h-[31px] font-normal w-[33px] px-[12px] py-[4px] rounded-[5px] text-sm bg-hownumber text-white text-center mr-7">4</span>Get content</h3>
                            <p className="text-sm leading-[25px] text-[#1F2021]">Get all the content you need
                                delivered to your inbox in 10-
                                12 business days</p>
                        </div>
                    </div>

                </div>
                <button onClick={openModal} className="w-[263px] h-[66px] text-[30px] text-white font-[900] shadow-[0px_5px_10px_0px_rgba(66,224,107,1)] bg-secondary mb-28">Let's Talk</button>
            </div>
            {modalOpen && (
                <>
                    <Modal isOpen={modalOpen} onClose={closeModal} >
                        <ContactForm />
                    </Modal>
                </>
            )}

        </>

    )
}

export default Why