import Symbol from '../images/Symbol.png';
import meta from '../images/meta.svg';
import tiktok from '../images/tiktok.svg';
import test from '../images/test.gif';
import test2 from '../images/test2.gif';
import test3 from '../images/test3.gif';
import test4 from '../images/test4.gif';
import test5 from '../images/test5.gif';
import test6 from '../images/test6.gif';
import test7 from '../images/test7.gif';
import test8 from '../images/test8.gif';
import test9 from '../images/test9.gif';
import test10 from '../images/test10.gif';
import test11 from '../images/test11.gif';
import test12 from '../images/test12.gif';



import React, {useEffect, useRef, useState} from 'react';
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import {toast, ToastContainer} from "react-toastify";


const addAnimation = (scrollers,scrollerclasses,animationsclass) => {
    scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", "true")
        const scrollerInner = scroller.querySelector(`${scrollerclasses}`);
        const scrollerContent = Array.from(scrollerInner.children);
        scrollerContent.forEach(item =>{
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", "true");
            scrollerInner.appendChild(duplicatedItem);
            scrollerInner.classList.add(`${animationsclass}`)
        })
    });
}

const Header = () => {
    const isFunctionCalled = useRef(false);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {

        setModalOpen(true);
        // setToasterOpen(false);
    }

    const closeModal = () => {
        setModalOpen(false);

    }


    useEffect(() => {
        const scrollers = document.querySelectorAll('.scroller');
        const scrollersMd = document.querySelectorAll('.scroller-md');
        const scrollersOne = document.querySelectorAll('.scroller-1');
        const scrollersTwo = document.querySelectorAll('.scroller-2');
        const scrollersThree = document.querySelectorAll('.scroller-3');
        const scrollersFour = document.querySelectorAll('.scroller-4');
        const scrollersOneB = document.querySelectorAll('.scroller-b1');

        if (!isFunctionCalled.current) {
            // Call the function
            addAnimation(scrollers,".scroller__inner","animate-upwardanimations");
            addAnimation(scrollersMd,".scroller__inner-md","animate-horizontalanimationsf");
            addAnimation(scrollersOne,".scroller__inner-1","animate-upwardanimations");
            addAnimation(scrollersTwo,".scroller__inner-2","animate-downwardanimations");
            addAnimation(scrollersThree,".scroller__inner-3","animate-horizontalanimations");
            addAnimation(scrollersFour,".scroller__inner-4","animate-horizontalanimations");
            addAnimation(scrollersOneB,".scroller__inner-b1","animate-horizontalanimations");


            // Update the ref to indicate that the function has been called
            isFunctionCalled.current = true;
        }
    }, []);

    return (
        <>
            <header className=" flex lg:flex-row md:flex-col-reverse md:mt-14 sm:mt-14 lg:mt-0 sm:w-full lg:w-[79%] xl:w-[75%] xxl:w-[70%] md:mx-auto
                                 sm:flex-col-reverse xxl:gap-0 xl:gap-20 sm:gap-10 justify-evenly items-center">
                <div className="lg:block lg:w-fit sm:flex sm:flex-col sm:justify-center sm:items-center lg:items-start flex-1 sm:w-full">
                    <h1 className="text-secondary md:text-[44px] sm:text-[25px] sm:text-center sm:leading-[30px] xl:w-[431px] lg:w-[350px] md:mb-3 lg:mb-0 md:w-full lg:text-start md:text-center xl:h-[161px] lg:h-full md:leading-[52px] md:pb-0 sm:pb-4 font-bold">Maximize your ROI
                        with direct video ad creatives</h1>
                    <p className="text-base font-medium sm:w-[75%] xxl:w-[65%] leading-6 sm:text-center lg:text-start  mb-[48px]">We deliver the advertising that your paid media
                        team requires to drive revenue and ROAS.</p>
                    <button onClick={openModal} className=" flex justify-center text-lg items-center mb-[48px] bg-btnblack font-bold text-textwhite uppercase px-12 py-4 rounded-full">Get Started <img src={Symbol} alt="symbol" className="pl-2 text-lg"></img> </button>
                    <div className="flex gap-4">
                        <div>
                            <img src={meta} className="w-[73px] h-[15px] mb-2" alt="meta"></img>
                            <p className="text-xs text-secondary">Business Partner</p>
                        </div>
                        <div className="sm:hidden" >
                            <img src={tiktok} className="mb-2.5" alt="tiktok"></img>
                            <p className="text-xs text-secondary">Creative Exchange</p>
                        </div>
                    </div>

                </div>
                <div className="h-[700px] w-fit lg:flex  overflow-hidden md:hidden sm:hidden justify-center items-start gap-10 flex-shrink">
                    <div data-animated="true" className="flex w-[186px] scroller max-h-[700px] transition-transform
                      flex-col items-start gap-10 flex-shrink">
                        <div className="scroller__inner flex w-fit flex-nowrap  flex-col gap-10">
                            <img src={test} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test2} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test3} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test4} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                        </div>

                    </div>
                    <div data-animated="true" className="flex w-[186px] scroller-2  max-h-[700px] transition-transform
                       flex-col items-start gap-10 flex-shrink">
                        <div className="scroller__inner-2 flex w-fit flex-nowrap justify-end flex-col gap-10">
                            <img src={test5} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test6} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test7} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test8} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>

                        </div>

                    </div>
                    <div data-animated="true" className="flex w-[186px] scroller-1 max-h-[700px] transition-transform
                      flex-col items-start gap-10 flex-shrink">
                        <div className="scroller__inner-1 flex w-fit flex-nowrap  flex-col gap-10">
                            <img src={test9} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test10} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test11} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test12} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                        </div>

                    </div>

                </div>
                <div className="w-full md:flex lg:hidden sm:flex overflow-hidden justify-center items-start gap-10 flex-shrink">
                    <div data-animated="true" className="flex w-[186px] md:flex-1 sm:flex-1 scroller-md max-h-[700px] transition-transform
                       items-start gap-10 flex-shrink">
                        <div className="scroller__inner-md flex w-fit flex-nowrap  gap-10">
                            <img src={test} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test2} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test3} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test4} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test5} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test6} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test7} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test8} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                            <img src={test9} alt="test" className="border-r-4 shadow-[5px_5px_0px_0px_rgba(105,69,216,0.5)]"/>
                        </div>

                    </div>


                </div>
            </header>
            {modalOpen && (
                <>
                    <Modal isOpen={modalOpen} onClose={closeModal} >
                        <ContactForm />
                    </Modal>
                </>
            )}



        </>

    )
}

export default Header
