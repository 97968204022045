import search from '../images/search.gif';
const Brand2 = () => {
    return (
        <div className="lg:-translate-y-[8.05rem] sm:-translate-y-0 bg-brand2bg lg:h-[1000px] sm:h-full bg-white">
            <div className="flex flex-col lg:gap-20 sm:gap-10 lg:overflow-visible sm:overflow-hidden justify-center pt-24 items-center">
                <div className="lg:w-[710px] sm:w-full">
                    <h2 className="font-[900] text-center lg:text-[42px] sm:text-[25px] leading-[48px] text-secondary"> Why every <span className="text-[#F44ED9]"> e-commerce brand </span>  NEEDS user-generated content?</h2>
                </div>
                <div className="flex lg:flex-row sm:flex-col items-center  xl:justify-center lg:justify-evenly gap-24">
                    <div className="lg:flex flex-col sm:hidden gap-10">
                        <div className="w-[240px] px-[32px] py-[45px]  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Increased Engagement</p></div>
                        <div className="w-[240px] px-[32px] py-[45px] xxl:-translate-x-64 xl:-translate-x-0 shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Enhanced Conversion Rates</p></div>
                        <div className="w-[240px] px-[32px] py-[45px] shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Cost-Effective Production</p></div>
                    </div>
                    <div className="bg-smartphonebg flex justify-center items-center h-[588px] w-[281px]">
                        <div className=" w-[260px] h-[566px] overflow-hidden rounded-[20px] ">
                            <img src={search} alt="search" />
                        </div>
                    </div>
                    <div className="lg:flex flex-col sm:hidden gap-10">
                        <div className="w-[240px] px-[32px] py-[45px]  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Authenticity and Trust</p></div>
                        <div className="w-[240px] px-[32px] py-[45px]  xxl:translate-x-64 xl:translate-x-0  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Real-time Relevance</p></div>
                        <div className="w-[240px] px-[32px] py-[45px] shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                            <p className="text-darkprimary text-xl text-center font-bold leading-6">Integration with E-commerce</p></div>
                    </div>
                    <div className="scroller-b1 mb-12 lg:hidden" data-animated="true">
                        <div className="sm:flex flex-row scroller__inner-b1  gap-10">
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px]  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Increased Engagement</p></div>
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px] xxl:-translate-x-64 xl:-translate-x-0 shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Enhanced Conversion Rates</p></div>
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px] shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Cost-Effective Production</p></div>
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px]  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Authenticity and Trust</p></div>
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px]  xxl:translate-x-64 xl:translate-x-0  shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Real-time Relevance</p></div>
                            <div className="w-[240px] px-[32px] pt-[32px] pb-[3px] shadow-[0px_-3px_32px_4px_rgba(6,5,174,0.20)] rounded-[16px] bg-white">
                                <p className="text-darkprimary text-xl text-center font-bold leading-6">Integration with E-commerce</p></div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Brand2