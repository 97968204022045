import storymain from "../images/storymain.gif";
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import {useState} from "react";



const Story = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {

        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);

    }
    return (
        <>
            <div className="lg:h-[615px] py-[78px] xxl:gap-52 xl:gap-40 lg:gap-20 md:gap-20 sm:gap-16 xl:px-0 lg:px-12 justify-center  flex lg:flex-row sm:flex-col sm:items-center bg-textwhite lg:-translate-y-[8.05rem] sm:h-full sm:-translate-y-[0rem]">
                <div className="flex flex-col justify-center relative after:content-afterstory
                            after:sm:hidden after:lg:block after:absolute after:bottom-0 after:left-0
                            after:-translate-x-24 after:-z-10 after:translate-y-20 before:content-beforestory
                            before:absolute before:top-0 before:right-0 before:lg:translate-x-20
                            before:lg:-translate-y-20 before:md:translate-x-20 before:md:-translate-y-14
                            before:-z-10 before:md:block before:sm:hidden  xl:max-w-[682px] lg:w-fit ">
                    <div className="sm:border-t-[2px] sm:border-r-[2px] sm:border-l-[2px] sm:border-darkprimary sm:h-[20px] lg:h-[30px] lg:pl-[10px] sm:flex sm:justify-start sm:items-center  sm:rounded-t-[10px]">
                        <svg className="pl-[5px]" width="46" height="12" viewBox="0 0 46 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="23" cy="6" r="5" stroke="#331A7E" strokeWidth="2"/>
                            <circle cx="6" cy="6" r="5" stroke="#331A7E" strokeWidth="2"/>
                            <circle cx="40" cy="6" r="5" stroke="#331A7E" strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="md:w-fit sm:border-[2px] sm:border-darkprimary sm:rounded-b-[10px] sm:w-[280px]  overflow-hidden">
                        <img src={storymain} alt="mainstory" className=""/>
                    </div>
                </div>
                <div className="flex flex-col xl:pl-0 lg:pl-28 justify-center lg:items-start sm:items-center max-w-[485px] mb-5">
                    <h2 className="text-secondary lg:text-[44px] sm:text-[25px] font-bold">Story Productions</h2>
                    <p className="text-sm text-justify mb-12 sm:px-8 md:px-0 leading-6">Let’s boost your sales and engagement. We produce premium content for your multi-channel distribution by combining high-end production resources with data-driven decision-making.</p>
                    <button onClick={openModal} className=" bg-secondary text-white  px-8 py-2 font-bold text-base rounded-full">Start a Project </button>
                </div>
            </div>
            {modalOpen && (
                <>
                    <Modal isOpen={modalOpen} onClose={closeModal} >
                        <ContactForm />
                    </Modal>
                </>
            )}

        </>
    )
}

export default Story