import logo from "../images/brandorama-01.png"
import cross from "../images/cross.svg"
import React, {useState} from "react";
import Modal from "./Modal";
import {Link} from "react-router-dom";
import ContactForm from "./ContactForm";




const Nav = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);


    const openNavbar = () => {
        setNavbarOpen(true);
    }
    const closeNavbar = () => {
        setNavbarOpen(false);
    }

    const openModal = () => {

        setModalOpen(true);

    }

    const closeModal = () => {
        setModalOpen(false);

    }





    return (
        <>
            <div className="flex z-10 bg-mainbg  sticky top-0 bg mt-5 md:mx-24 sm:mx-4 px-4 py-4 border-2
            border-primary justify-between text-primary ">

                <Link to={"/"} className="flex gap-5 justify-center items-center">
                    <img src={logo} alt="logo" className="w-8" />
                    <h2 className="uppercase font-bold text-lg">Brand-o-rama</h2>
                </Link>

                <ul className="xl:flex xxl:flex lg:flex md:hidden sm:hidden items-center gap-5 text-sm uppercase justify-between font-bold">
                    <li>
                        <Link to="/ourwork" >our work</Link>
                    </li>
                    <li>
                        <Link to="/aboutus" >about us</Link>
                    </li>
                    <li onClick={openModal}>contact us</li>
                    <li>
                        <button className=" bg-secondary text-textwhite uppercase px-8 py-2 rounded-full"  onClick={openModal}>Book A call </button>
                    </li>
                </ul>
                <div className="md:block lg:hidden xxl:hidden sm:block" onClick={openNavbar} >
                    <svg width="50px" className="text-lg" height="50px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 11.75C6.08579 11.75 5.75 12.0858 5.75 12.5C5.75 12.9142 6.08579 13.25 6.5 13.25V11.75ZM18.5 13.25C18.9142 13.25 19.25 12.9142 19.25 12.5C19.25 12.0858 18.9142 11.75 18.5 11.75V13.25ZM6.5 15.75C6.08579 15.75 5.75 16.0858 5.75 16.5C5.75 16.9142 6.08579 17.25 6.5 17.25V15.75ZM18.5 17.25C18.9142 17.25 19.25 16.9142 19.25 16.5C19.25 16.0858 18.9142 15.75 18.5 15.75V17.25ZM6.5 7.75C6.08579 7.75 5.75 8.08579 5.75 8.5C5.75 8.91421 6.08579 9.25 6.5 9.25V7.75ZM18.5 9.25C18.9142 9.25 19.25 8.91421 19.25 8.5C19.25 8.08579 18.9142 7.75 18.5 7.75V9.25ZM6.5 13.25H18.5V11.75H6.5V13.25ZM6.5 17.25H18.5V15.75H6.5V17.25ZM6.5 9.25H18.5V7.75H6.5V9.25Z" fill="#DB7C2C"/>
                    </svg>
                </div>
            </div>
            {navbarOpen && (
                <>
                    <div className={navbarOpen?"w-1/2 lg:hidden sm:flex fixed {animate-sliedinanimations} top-0 left-0 z-30 h-full bg-mainbg":""}>
                        <div className="flex gap-10 w-full py-10 px-10 flex-col">
                            <div className="h-[25px] w-[25px] self-end" onClick={closeNavbar}>
                                <img src={cross} alt="cross" />
                            </div>
                            <ul className="flex flex-col gap-5 text-sm uppercase text-secondary font-bold">
                                <li><Link to="/ourwork" >our work</Link></li>
                                <li><Link to="/aboutus" >about us</Link></li>
                                <li onClick={openModal}>contact us</li>
                                <li onClick={openModal}>Book A call</li>
                            </ul>
                        </div>

                    </div>
                </>
            )}
            {modalOpen && (
                <>
                    <Modal isOpen={modalOpen} onClose={closeModal} >
                        <ContactForm />
                    </Modal>

                </>

            )}

        </>

    )
}

export default Nav