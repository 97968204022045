import {useState, useEffect} from "react";

const Ourwork = () => {
    const [content, setContent] = useState([]);

    useEffect(() => {

        getContent();
    }, []);

    const getContent = async () => {
        try {
            const response = await fetch('https://admin.brandorama.in/backend/ourwork/');

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setContent(data);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return (
        <>
            {content.length !== 0 && (
                <div className=" flex flex-col gap-16 justify-center items-center pt-10 ">
                    <h2 className="lg:text-[42px] sm:text-[25px] font-[900] text-secondary ">Our works</h2>

                    <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 w-full justify-center
                            lg:mb-52 sm:mb-32 items-center xl:px-40 lg:px-28 md:px-20 sml:px-16 sm:px-12 gap-10">
                        {content.map((item) => {
                            return (
                                <div className="flex justify-center flex-1 items-center flex-col gap-5" key={item.title}>
                                    {/*<h3 className="text-lg font-[900] text-secondary">{item.title}</h3>*/}
                                    <video src={item.video} className=" rounded-[12px]" controls></video>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

export default Ourwork
