import about1 from '../images/about1.png'
import about2 from '../images/about2.png'
import about3 from '../images/about3.png'
import about4 from '../images/about4.png'
import about5 from '../images/about5.png'
import about6 from '../images/about6.png'
import about7 from '../images/about7.png'
import about8 from '../images/about8.png'
import about9 from '../images/about9.png'
import about10 from '../images/about10.png'
const Aboutus = () => {


    return (
        <div className="pt-20 mb-52 flex flex-col justify-center items-center gap-10">
            <h2 className="lg:text-[42px] sm:text-[25px] font-[900] text-secondary ">About Us</h2>
            <p className="text-footerbg text-lg w-[70vw] text-center">Our UGC agency has the passion for creating engaging innovative and authentic video content. Its inherent aspects include authenticity, innovation, and creativity to deliver captivating stories and experiences using niche specific influencers.
                We have one of the largest pools of video directors, editors, scriptwriters, story tellers, 2d-3d animators, marketers, motion graphics designers, content creators, social media influencers, and much more!
                We are passionate to help drive positive change through videos.
                We work together to bring your vision to life.
                We are well versed in all niches and through effective storytelling, we strive to deliver desired results.</p>
            <h2 className="lg:text-[42px] sm:text-[25px] font-[900] text-secondary ">What We Do</h2>
            <p className="text-footerbg text-lg w-[70vw] text-center"> We leverage the associated influence and trust of social media influencers and create a customised video, based on the set objectives of clients. The resultant videos are on par organic, authentic, and relevant that allows brands to register high ROIs.</p>
            <div className="my-10">
                <h2 className="bg-secondary px-8 p-3 text-3xl  rounded-md text-center text-white ">UGC Videos:</h2>
            </div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 justify-center items-center gap-10">
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about1} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Product Review Videos</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about2} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Testimonial Videos</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about3} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Social Media Videos</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about4} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Live Video Streams</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about5} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Unboxing Videos</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about6} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Post Production</p>
                </div>
            </div>
            <div className="my-10">
                <h2 className="bg-secondary px-8 p-3 text-3xl  rounded-md text-center text-white ">Video Productions:</h2>
            </div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 justify-center items-center gap-10">
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about7} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Concept Development</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about8} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Pre-production</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about9} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Production</p>
                </div>
                <div className="flex flex-col  justify-center relative after:content-[''] after:rounded-2xl after:absolute after:bottom-0 after:left-0 after:-z-10 after:h-[64%] after:w-full after:bg-aboutbg items-center">
                    <img src={about10} alt="about" className=""/>
                    <p className="px-12 text-xl font-semibold text-footerbg pt-6 pb-10">Post Production</p>
                </div>

            </div>


        </div>
    )
}

export default Aboutus