import ugc from "../images/ugc.gif";
import ugc2 from "../images/ugc2.gif";
import ugc3 from "../images/ugc3.gif";
import frame from "../images/frame.svg";
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import {useState} from "react";
;
const Ugc = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {

        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);

    }

    return (
        <>
        <div className="h-[38.43rem] py-[4.87rem] gap-32 flex lg:flex-row sm:flex-col-reverse justify-center bg-ugcbg lg:-translate-y-[8.05rem] sm:translate-y-0 sm:gap-10 sm:h-full">
            <div className="flex flex-col justify-center lg:items-start sm:items-center xxl:max-w-[32.31rem] xl:max-w-[20.31rem] xl:pl-0 lg:pl-[80px] lg:max-w-[23.31rem] mb-5">
                <h2 className="text-ugctext md:text-[44px] sm:text-[25px] font-bold">Performance UGC</h2>
                <p className="text-sm  mb-12 lg:text-start lg:px-0 md:px-5 sm:text-center leading-6">No, it's not "real UGC." It's better.
                    We are an elite team of creative strategists, experienced
                    actors and skilled video editors in full control of the content
                    we deliver. With that comes the ultimate perk - the flexibility
                    to quickly iterate creatives based on performance data.</p>
                <button onClick={openModal} className=" bg-ugcbtn text-white  px-8 py-2 font-bold text-base rounded-full">Start a Project </button>
            </div>
            <div className="flex xl:h-auto lg:h-[22.87rem] sm:h-auto md:gap-6 sm:gap-4 sm:mx-[10px]">

                <div className="border-ugctext border-[2px] rounded-[15px] p-[4px] flex justify-center flex-col items-center">
                    <div className="md:h-[35px] sm:h-[20px] sm:p-[2px] sm:rounded-t-[10px] relative before:absolute before:w-[30%] before:h-[8px] before:rounded-[15px] before:content-[''] border-r-[2px] border-l-[2px] before:top-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:border-ugctext before:border-[2px]  before:left-[50%] border-t-[2px] md:rounded-t-[15px] w-full md:p-4px border-ugctext ">
                    </div>
                    <div className="border-[2px] border-ugctext">
                        <img src={ugc} alt="ugc" className=" -z-10 self-center  "/>
                    </div>
                    <div className="md:h-[35px] sm:h-[20px] sm:p-[2px] sm:rounded-b-[10px] border-r-[2px] border-l-[2px] border-b-[2px] md:rounded-b-[15px] border-ugctext md:p-[4px] w-full"></div>
                </div>
                <div className="border-ugctext border-[2px] rounded-[15px] p-[4px] flex justify-center flex-col items-center">
                    <div className="md:h-[35px] sm:h-[20px] sm:p-[2px] sm:rounded-t-[10px] relative before:absolute before:w-[30%] before:h-[8px] before:rounded-[15px] before:content-[''] border-r-[2px] border-l-[2px] before:top-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:border-ugctext before:border-[2px]  before:left-[50%] border-t-[2px] md:rounded-t-[15px] w-full md:p-4px border-ugctext ">
                    </div>
                    <div className="border-[2px] border-ugctext">
                        <img src={ugc2} alt="ugc" className=" -z-10 self-center  "/>
                    </div>
                    <div className="md:h-[35px] sm:h-[20px] sm:p-[2px] sm:rounded-b-[10px] border-r-[2px] border-l-[2px] border-b-[2px] md:rounded-b-[15px] border-ugctext md:p-[4px] w-full"></div>
                </div>
                <div className="border-ugctext border-[2px] sm:hidden rounded-[15px] p-[4px] md:flex justify-center flex-col items-center">
                    <div className="h-[35px] relative before:absolute before:w-[30%] before:h-[8px] before:rounded-[15px] before:content-[''] border-r-[2px] border-l-[2px] before:top-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:border-ugctext before:border-[2px]  before:left-[50%] border-t-[2px] rounded-t-[15px] w-full p-4px border-ugctext ">
                    </div>
                    <div className="border-[2px] border-ugctext">
                        <img src={ugc3} alt="ugc" className=" -z-10 self-center  "/>
                    </div>
                    <div className="h-[35px] border-r-[2px] border-l-[2px] border-b-[2px] rounded-b-[15px] border-ugctext p-[4px] w-full"></div>
                </div>

            </div>

        </div>
            {modalOpen && (
                <>
                    <Modal isOpen={modalOpen} onClose={closeModal} >
                        <ContactForm />
                    </Modal>
                </>
            )}

        </>
    )
}

export default Ugc