import Nav from './components/Nav';
import Home from "./components/Home";
import Ourwork from "./components/Ourwork";
import Aboutus from "./components/Aboutus";
import Footer from "./components/Footer";
import {Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
function App() {
  return (
    <div className="relative ">

        <div className="bg-primary  flex  justify-center">
            <p className="text-textwhite text-sm py-1.5">
                Fully booked for Q1 💛 Let's chat about Q2!
            </p>
        </div>
        <ToastContainer />

        <Nav />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ourwork" element={<Ourwork />} />
            <Route path="/aboutus" element={<Aboutus />} />
        </Routes>
        <Footer />

    </div>
  );
}

export default App;
