import gal1 from '../images/gal1.gif';
import gal2 from '../images/gal2.gif';
const Gallary = () => {
    return (
        <div className="flex overflow-hidden md:flex-row sm:flex-col lg:-translate-y-[8.05rem] sm:-translate-y-0">
            <div className="flex">
                <div className="w-full h-full flex-1">
                    <img src={gal1} alt="gal1" />
                </div>
                <div className="w-full h-full flex-1">
                    <img src={gal2} alt="gal1" />
                </div>
                <div className="w-full h-full flex-1">
                    <img src={gal1} alt="gal1" />
                </div>

            </div>
            <div className="flex">
                <div className="w-full h-full flex-1">
                    <img src={gal1} alt="gal1" />
                </div>
                <div className="w-full h-full flex-1">
                    <img src={gal2} alt="gal1" />
                </div>
                <div className="w-full h-full flex-1">
                    <img src={gal1} alt="gal1" />
                </div>
            </div>
        </div>
    );
}

export default Gallary;