
import {Button, Input, Menu, MenuHandler, MenuItem, MenuList, Textarea} from "@material-tailwind/react";
import React from "react";
import {useCountries} from "use-react-countries";

const ContactForm = () => {

    const { countries } = useCountries();
    const [country, setCountry] = React.useState(0);
    const { name, flags, countryCallingCode } = countries[country];
    const [userName, setUserName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [brand, setBrand] = React.useState("");
    const [brandWebsite, setBrandWebsite] = React.useState("");
    const [requirements, setRequirements] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("");
    const handleUserName = (e) => {
        setUserName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handlePhone = (e) => {
        setPhone(e.target.value);
    }
    const handleBrand = (e) => {
        setBrand(e.target.value);
    }
    const handleBrandWebsite = (e) => {
        setBrandWebsite(e.target.value);
    }
    const handleRequirements = (e) => {
        setRequirements(e.target.value);
    }
    const handleCountryCode = (e) => {
        console.log(e)

        setCountryCode(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            "name": userName,
            "email": email,
            "numbers":phone,
            "brandname":brand,
            "brandwebsite":brandWebsite,
            "requirments":requirements,
            // "countrycode":countryCode,
        };
        console.log(data);
        postData(data);
        // handleClose();
    }

    const postData = async (data) => {
        try {
            const response = await fetch("http://127.0.0.1:8000/backend/contact/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            console.log(response);
            return response.json();
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <form className="flex flex-col gap-4">
                <Input label="Name"  onChange={handleUserName} />
                <div className="relative flex w-full max-w-[24rem]">
                    <Menu placement="bottom-start">
                        <MenuHandler>
                            <Button
                                onChange={handleCountryCode}
                                ripple={false}
                                variant="text"
                                color="blue-gray"
                                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                            >
                                <img
                                    src={flags.svg}
                                    alt={name}
                                    className="h-4 w-4 rounded-full object-cover"
                                />
                                {countryCallingCode}
                            </Button>
                        </MenuHandler>
                        <MenuList className="max-h-[20rem] max-w-[18rem]">
                            {countries.map(({ name, flags, countryCallingCode }, index) => {
                                return (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        className="flex items-center gap-2"
                                        onClick={() => setCountry(index)}
                                    >
                                        <img
                                            src={flags.svg}
                                            alt={name}
                                            className="h-5 w-5 rounded-full object-cover"
                                        />
                                        {name} <span className="ml-auto">{countryCallingCode}</span>
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Menu>
                    <Input
                        type="tel"
                        placeholder="Mobile Number"
                        className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        containerProps={{
                            className: "min-w-0",
                        }}
                        onChange={handlePhone}
                    />
                </div>
                <Input type="email" label="Email Address" onChange={handleEmail} />
                <Input label="Brand Name" onChange={handleBrand} />
                <Input label="Brand Website" onChange={handleBrandWebsite} />
                <Textarea variant="outlined" label="requirement" onChange={handleRequirements}/>
                <div className="self-center flex justify-center items-center">
                    <button
                        className="select-none rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default ContactForm