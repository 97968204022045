import Header from "./Header";
import Brand from "./Brand";
import Ugc from "./Ugc";
import Brand2 from "./Brand2";
import Story from "./Story";
import Why from "./Why";
import Gallary from "./Gallary";


const Home = () => {
    return (
        <>
            <Header />
            <Brand />
            <Ugc />
            <Brand2 />
            <Story />
            <Why />
            {/*<Gallary />*/}
        </>
    )
}

export default Home