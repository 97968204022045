import wave from "../images/wave.svg";

// import client1 from "../images/client-Dermatica.png.svg";
// import client2 from "../images/client-Dr_Smile.png.svg";
// import client3 from "../images/client-Gaia-Herbs.png.svg";
// import client4 from "../images/client-Hills.png.svg";
// import client5 from "../images/client-Jack-Archer.png.svg";

import client1 from "../images/client1.png";
import client2 from "../images/client2.jpg";
import client3 from "../images/client3.png";
import client4 from "../images/client4.png";
import client5 from "../images/client5.png";
import client6 from "../images/client6.png";
import client7 from "../images/client7.jpg";
import client8 from "../images/client8.jpg";
import client9 from "../images/client9.jpg";
import client10 from "../images/client10.png";
import client11 from "../images/client11.png";





const Brand = () => {

    return (
        <>
            <div>
                <img src={wave} alt="wave" className="lg:-translate-y-32 sm:-translate-y-0 sm:mt-10 lg:mt-0"/>
            </div>
            <div className=" lg:-translate-y-[8.05rem] sm:-translate-y-[1px] pt-[3.25rem] xxl:px-[17.5rem] xl:px-[11.8rem] lg:px-[6rem] pb-[4.68rem]   flex  items-center gap-10 bg-textwhite">
                <div className="flex lg:flex-row sm:flex-col sm:w-full justify-between items-center gap-10">
                    <div className="flex ">
                        <h2 className="uppercase font-bold text-2xl text-darkprimary">
                            Trusted by
                            70+ brands
                        </h2>
                    </div>
                    <div className=" lg:w-fit overflow-hidden sm:w-full flex items-center justify-center ">
                            <div data-animated="true" className="flex h-[64px]  scroller-3 gap-10 ">
                                <div className="scroller__inner-3 flex w-fit h-fit gap-10 justify-center items-center ">
                                    <img src={client1} alt={client1} className="h-[64px] " />
                                    <img src={client2} alt={client1} className="h-[64px]  " />
                                    <img src={client3} alt={client1} className="h-[64px] " />
                                    <img src={client4} alt={client1} className="h-[64px]  " />
                                    <img src={client5} alt={client1} className="h-[64px]  " />
                                    <img src={client6} alt={client1} className="h-[64px] " />
                                    <img src={client7} alt={client1} className="h-[64px] " />
                                    <img src={client8} alt={client1} className="h-[64px]" />
                                    <img src={client9} alt={client1} className="h-[64px]" />
                                    <img src={client10} alt={client1} className="h-[64px]" />
                                    <img src={client11} alt={client1} className="h-[64px]" />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Brand