import React from "react";
import logo from "../images/brandorama-01.png";
import cross from "../images/cross.svg";
import {Button, Input, Menu, MenuHandler, MenuItem, MenuList, Textarea} from "@material-tailwind/react";
import {useCountries} from "use-react-countries";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Modal = ( { isOpen, onClose, children } ) => {

    const closeModal = () => {
        onClose();
    };

    const { countries } = useCountries();
    const [country, setCountry] = React.useState(0);
    let { name, flags, countryCallingCode } = countries[country];
    const [userName, setUserName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [brand, setBrand] = React.useState("");
    const [brandWebsite, setBrandWebsite] = React.useState("");
    const [requirements, setRequirements] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("");


    for (let i = 0; i < countries.length; i++) {
        if (countries[i].name === "India") {
            countryCallingCode = countries[i].countryCallingCode;
            flags = countries[i].flags;
            name = countries[i].name;

        }
    }

    const handleUserName = (e) => {
        setUserName(e.target.value);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    }
    const handlePhone = (e) => {
        setPhone(e.target.value);
    }
    const handleBrand = (e) => {
        setBrand(e.target.value);
    }
    const handleBrandWebsite = (e) => {
        setBrandWebsite(e.target.value);
    }
    const handleRequirements = (e) => {
        setRequirements(e.target.value);
    }
    const handleCountryCode = (e) => {
        console.log(e)

        setCountryCode(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            "name": userName,
            "email": email,
            "numbers":phone,
            "brandname":brand,
            "brandwebsite":brandWebsite,
            "requirments":requirements,
            // "countrycode":countryCode,
        };

        postData(data);






        // handleClose();
    }

    const postData = async (data) => {
        try {
            const response = await fetch("https://admin.brandorama.in/backend/contact/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.status === 201){
               closeModal();
               toast.success("Thank You! We will get back to you soon")

            }else {
                toast.error("Something went wrong")
            }

            // return response.json();
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
            <>

                <div className="bg-modalbg-bg flex justify-around items-center fixed top-0 right-0  z-20 w-screen h-screen">
                    <div className="flex justify-center h-fit md:px-24 md:py-20 sm:px-8 sm:py-16 relative w-fit bg-mainbg rounded-md flex-col items-center">
                        <img src={cross} alt="logo" className=" self-end absolute top-0 right-0 pt-4 pr-4 " onClick={closeModal}/>
                        <div className="w-full flex flex-col gap-10 justify-center items-center">
                            <div className="flex justify-around gap-6 items-center">
                                <img src={logo} alt="logo" className="w-8" />
                                <h2 className="uppercase font-bold text-primary text-2xl">Brand-o-rama</h2>
                            </div>
                            <form className="flex flex-col gap-4">
                                <Input label="Name"  onChange={handleUserName} />
                                <div className="relative flex w-full max-w-[24rem]">
                                    <Menu placement="bottom-start">
                                        <MenuHandler>
                                            <Button
                                                onChange={handleCountryCode}
                                                ripple={false}
                                                variant="text"
                                                color="blue-gray"
                                                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                                            >
                                                <img
                                                    src={flags.svg}
                                                    alt={name}
                                                    className="h-4 w-4 rounded-full object-cover"
                                                />
                                                {countryCallingCode}
                                            </Button>
                                        </MenuHandler>
                                        <MenuList className="max-h-[20rem] max-w-[18rem]">
                                            {countries.map(({ name, flags, countryCallingCode }, index) => {
                                                return (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                        className="flex items-center gap-2"
                                                        onClick={() => setCountry(index)}
                                                    >
                                                        <img
                                                            src={flags.svg}
                                                            alt={name}
                                                            className="h-5 w-5 rounded-full object-cover"
                                                        />
                                                        {name} <span className="ml-auto">{countryCallingCode}</span>
                                                    </MenuItem>
                                                );
                                            })}
                                        </MenuList>
                                    </Menu>
                                    <Input
                                        type="tel"
                                        placeholder="Mobile Number"
                                        className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                                        labelProps={{
                                            className: "before:content-none after:content-none",
                                        }}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                        onChange={handlePhone}
                                    />
                                </div>
                                <Input type="email" label="Email Address" onChange={handleEmail} />
                                <Input label="Brand Name" onChange={handleBrand} />
                                <Input label="Brand Website" onChange={handleBrandWebsite} />
                                <Textarea variant="outlined" label="Requirement" onChange={handleRequirements}/>
                                <div className="self-center flex justify-center items-center">
                                    <button
                                        className="select-none rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                            {/*{children}*/}
                        </div>
                    </div>
                </div>

            </>
        );



}

export default Modal;
